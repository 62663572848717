.app__specialMenu {
    flex-direction: column;
    background: var(--color-black);
}

.app__specialMenu-title {
    margin-bottom: 2rem;
    text-align: center;
}

.app__specialMenu-menu {
    width: 100%;

    margin: 2rem 0;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: row;
}

.app__specialMenu-menu_heading {
    font-family: var(--font-base);
    font-weight: 600;
    font-size: 45px;
    line-height: 58.5px;
    letter-spacing: 0.04em;
    color: var(--color-white);
}

.app__specialMenu-menu_wine, .app__specialMenu-menu_cocktails {
    flex: 1;
    width: 100%;
    flex-direction: column;
    padding: 2rem;
    
    
}

.app__specialMenu-menu_img {
    width: 412px;
    margin: 0 3rem;
    padding-left: 3rem;
}

.app__specialMenu-menu_img img {
    
    width: 100%;
    height: auto;
}

.app__specialMenu-menu_items {
    display: flex;
    flex-direction: column;


    width: 100%;
}

@media screen and (min-width: 2000px) {
    .app__specialMenu-menu_img img {
        height: 980px;
        width: 550px;
        margin-top: -2rem;
        margin-left: -2rem;
    }

    
    .app__specialMenu-menu_img {
        margin: 3rem 5rem;
        padding: 0;
    }
}

@media screen and (max-width: 2000px) {
    .app__specialMenu-menu_img img {
        height: 690px;
        margin-top: -2rem;
    }

    .app__specialMenu-menu_img {
        margin: 3rem 0;
    }
}

@media screen and (max-width: 1150px) {
    .app__specialMenu-menu {
        flex-direction: column;
        align-items: center;
        width: 100%;
    }

    .app__specialMenu-menu_img {
        margin: 3rem 0;
    }

    .app__specialMenu-menu_wine, .app__specialMenu-menu_cocktails {
        padding: 2rem;
        margin-right: 3rem;
    }
}

@media screen and (max-width: 650px) {
    .app__specialMenu-menu_img {
        width: 100%;
    }

    .app__specialMenu-menu_heading {
        font-size: 35px;
        line-height: 48.5px;
    }

    .app__specialMenu-menu_wine, .app__specialMenu-menu_cocktails {
        padding: 0;
        margin-right: 3rem;
    }

    .app__specialMenu-menu_img img {
        height: 580px;
    }
    
}

@media screen and (max-width: 650px) {
    .app__specialMenu-menu_img img {
        height: 420px;
    }
}

@media screen and (max-width: 430px) {
    .app__specialMenu-menu_img img {
        height: 520px;
    }
}